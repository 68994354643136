@import '~antd/lib/style/themes/default.less';
@grey-7: #8C8C8C;

.copy-icon, .minus-icon {
    color: @grey-7;

    &:hover {
        color: @blue-6;
    }
}

.shipping-input {
    margin: 8px 0 16px;
    width: 90%;
}

.add-product-divider {
    opacity: 0.25;
}
.add-product-divider:hover {
    opacity: 1;
}