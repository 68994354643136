.database-search {
    padding: 24px;

    .ant-space {
        margin-bottom: 16px;
    }

    .ant-input {
        width: 200px;
    }
} 