@import '~antd/dist/antd.less';

.profile-pic {
		left: -19.57%;
		right: -15.22%;
		top: 0%;
		bottom: -2.17%;
		padding-left: 10px;
}

.site-layout-background {
	background: #fff;
}

.site-layout-sidebar {
    box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.15);
    min-height: 1000px;
}


#upload-zone {
    background: #fafafa;
    border: 1px dashed #d9d9d9;
    border-radius: 4px;
    padding: 50px;
    text-align: center;
    cursor: pointer;
    transition: border-color .3s;
    transition-property: border-color;
    transition-duration: 0.3s;
    transition-timing-function: ease;
    transition-delay: 0s;
    &:hover {
        border-color: #40a9ff;
    }
}

.memo-button {
	color: lightgrey;
	font-size: 20px;
}

.memo-button:hover {
	color: black;
}

.customer-details-section {
    flex-grow: 1;
    margin: 1rem;
    min-height: 40vh;
    min-width: 30vw;
    max-width: 40vw
}

.spin-center {
    margin: 50%;
    margin-top: 40%;
}

.algolia-logo {
   height: 16px;
}
.ant-message-error {
    font-size: 16px;
}

.hide-number-arrows {
    ::-webkit-inner-spin-button, 
    ::-webkit-outer-spin-button { 
        -webkit-appearance: none; 
        margin: 0; 
    }
}

.product-checkbox > .ant-checkbox > .ant-checkbox-inner {
    width: 1.3rem;
    height: 2rem;
}

.ant-checkbox {
    top: 0rem;
}

.ant-select.not-selected .ant-select-selector {
    background-color: #FFFAB5;
}