#components-layout-demo-top-side-2 .logo {
	width: 120px;
	height: 31px;
	background: red;
	margin: 16px 28px 16px 0;
	float: left;
}

.main-header {
    text-align: right;
		position: fixed;
    z-index: 10;
    border-bottom: 1px solid #f0f0f0;
		padding-left: 24px;
		padding-right: 24px;
}

.user-panel {
    float: right;
    width: 200px;
}

.app-logo {
	width: 127px;
	height: 17.5px;
}

.app-logo-collapsed {
	width: 17.5px;
	height: 17.5px;
}